import { EXP_VIEWED_VERSION } from '@growthBookExperimentation/Constants/Growthbook.constants';

import { Schema } from '../../Entities/Schema.entity';
import { createSchema } from '../../Utils/CreateSchema';

export const experimentViewedSchema: Schema = {
  ...createSchema({
    version: EXP_VIEWED_VERSION,
    description: 'Key information of the experiment',
    name: 'experiment_viewed',
    properties: {
      experiment_id: {
        type: 'string',
        description: 'Identifer of the experiement from Growth Book',
      },
      variation_id: {
        type: 'string',
        description: 'The variation of the experiement from Growth Book',
      },
    },
  }),
};
