import { Schema } from '../../../Entities/Schema.entity';
import { createSchema } from '../../../Utils/CreateSchema';

export const checkoutAccountSchema: Schema = {
  ...createSchema({
    version: '1-0-0',
    description: 'Checkout account entry point clicked',
    name: 'checkout_account_entry_point',
    properties: {},
  }),
};
