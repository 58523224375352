import { Schema } from '../../Entities/Schema.entity';
import { createSchema } from '../../Utils/CreateSchema';

export const recentlyViewedClickSchema: Schema = {
  ...createSchema({
    version: '1-0-0',
    description:
      'A recently viewed click event, fires when a user clicks on a recently viewed listing',
    name: 'recently_viewed_click',
    properties: {
      listing_id: {
        type: 'string',
        description: 'The id of the listing',
      },
    },
    required: ['listing_id'],
  }),
};
