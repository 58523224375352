import { NativeScreenType } from '../Entities/NativeScreenType.entity';

export const NATIVE_SCREEN_TYPES = [
  'homepage',
  'plp',
  'pdp',
  'favourites',
  'fallback',
  'external',
  'promotions',
  'leasing',
  'account',
  'sell',
] as const;

export const NATIVE_SCREEN_TYPE_MAP: Record<Exclude<NativeScreenType, 'fallback'>, RegExp> = {
  homepage: /^(\/(uk|fr))(\/?)$/,
  plp: /^(\/(uk|fr))?\/autos/,
  pdp: /^(\/(uk|fr))?\/auto\/(?!vehicle-history)/,
  favourites: /^(\/(uk|fr))?\/favourites/,
  account: /^(\/(uk|fr))?\/account/,
  promotions: /^(\/(uk|fr))?\/promotions/,
  leasing: /^(\/(uk|fr))?\/leasing/,
  sell: /^(\/(uk|fr))?\/value-my-car/,
  external: /^http/,
};

export const NATIVE_SCREEN_TITLE_MAP: Record<string, RegExp> = {
  heycar: /^(\/(uk|fr))(\/?)$/,
  'Loading results...': /^(\/(uk|fr))?\/autos/,
  'Loading vehicle...': /^(\/(uk|fr))?\/auto\/(?!vehicle-history)/,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Favourites: /^(\/(uk|fr))?\/favourites/,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Promotions: /^(\/(uk|fr))?\/promotions/,
  'Value my car': /^(\/(uk|fr))?\/value-my-car/,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Leasing: /^(\/(uk|fr))?\/leasing/,
  'My account': /^(\/(uk|fr))?\/account/,
};

export const NATIVE_LOADER_MAP: Record<string, RegExp> = {
  1: /^(\/(uk|fr))?\/auto(s)?/,
};
