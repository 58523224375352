import {
  NATIVE_SCREEN_TITLE_MAP,
  NATIVE_SCREEN_TYPE_MAP,
} from '../Constants/NativeScreenTypes.constants';
import { NativeMessageData } from '../Entities/NativeMessageTypes.entity';
import { NativeScreenType } from '../Entities/NativeScreenType.entity';

export const sanitizePath = (pathname: string): string => {
  if (!pathname) return '';

  let sanitizedPath = pathname;

  if (sanitizedPath.startsWith('http') && sanitizedPath.includes('heycar.com')) {
    sanitizedPath = sanitizedPath.replace('https://heycar.com', '');
  }
  if (!sanitizedPath.startsWith('/') && !/^(https?|mailto|tel|#)/.test(sanitizedPath)) {
    sanitizedPath = `/${sanitizedPath}`;
  }
  return sanitizedPath;
};
export function createNativeMessage(sanitizedHref: string): NativeMessageData {
  const screenType = getScreenTypeFromPath(sanitizedHref);
  const updateHeader = getScreenTitleFromPath(sanitizedHref);

  const message: NativeMessageData = {
    updateHeader: updateHeader,
  };

  if (sanitizedHref.startsWith('tel:')) {
    return {
      triggerAction: {
        action: 'call',
        payload: sanitizedHref.substring(4),
      },
    };
  } else {
    return {
      navigate: {
        url: sanitizedHref,
        screenType: screenType,
      },
      updateHeader: getScreenTitleFromPath(sanitizedHref),
    };
  }

  return message;
}

export const getScreenTypeFromPath = (pathname: string): NativeScreenType => {
  for (const [screenType, regex] of Object.entries(NATIVE_SCREEN_TYPE_MAP)) {
    if (regex.test(pathname)) {
      return screenType as NativeScreenType;
    }
  }

  return 'fallback';
};

export const checkShouldHideLoader = (pathname: string) => {
  for (const [_, regex] of Object.entries(NATIVE_SCREEN_TYPE_MAP)) {
    if (regex.test(pathname)) return false;
  }

  return true;
};

export const getScreenTitleFromPath = (pathname: string): string => {
  for (const [screenTitle, regex] of Object.entries(NATIVE_SCREEN_TITLE_MAP)) {
    if (regex.test(pathname)) {
      return screenTitle as NativeScreenType;
    }
  }

  return ' ';
};
