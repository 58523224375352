import { Schema } from '../../../Entities/Schema.entity';
import { createSchema } from '../../../Utils/CreateSchema';

export const purposeSchema: Schema = {
  ...createSchema({
    version: '1-0-0',
    description: 'Consent from users for opting feature/purpose ',
    name: 'user_consent',
    properties: {
      has_accepted: {
        type: 'boolean',
        description: 'If the user has accepted or rejected consent',
      },
    },
    required: ['has_accepted'],
  }),
};
