/* istanbul ignore file */

import Ajv from 'ajv';

import { clientLog } from '@core/Utils/Log/ClientLog';

import { Schema } from '../Entities/Schema.entity';

export const validateSchema = (schema: Schema, event: Record<string, any>) => {
  const ajv = new Ajv({
    strict: false,
  });

  const validate = ajv.compile({ ...schema, $schema: undefined });
  const isValid = validate(event.data);
  if (!isValid)
    clientLog({
      label: `Event validation error: ${schema.self.name}]`,
      data: { errors: validate.errors, data: event.data },
      color: '#fff1ef',
    });
};
