import { Schema } from '../../Entities/Schema.entity';
import { createSchema } from '../../Utils/CreateSchema';

export const latestAttributionSchema: Schema = {
  ...createSchema({
    version: '1-0-1',
    description: 'Latest attribution data for app marketing and tracking',
    name: 'latest_attribution',
    properties: {
      twitter_card: {
        type: 'string',
        description: 'Twitter card type',
      },
      marketing: {
        type: 'boolean',
        description: 'Marketing flag',
      },
      feature: {
        type: 'string',
        description: 'Feature name',
      },
      clicked_branch_link: {
        type: 'boolean',
        description: 'Whether branch link was clicked',
      },
      canonical_url: {
        type: 'string',
        description: 'Canonical URL',
      },
      match_guaranteed: {
        type: 'boolean',
        description: 'Match guarantee flag',
      },
      click_timestamp: {
        type: 'number',
        description: 'Timestamp of click',
      },
      id: {
        type: 'number',
        description: 'Unique identifier',
      },
      creation_source: {
        type: 'number',
        description: 'Creation source identifier',
      },
      og_description: {
        type: 'string',
        description: 'Open Graph description',
      },
      channel: {
        type: 'string',
        description: 'Channel name',
      },
      referring_link: {
        type: 'string',
        description: 'Referring link URL',
      },
      twitter_title: {
        type: 'string',
        description: 'Twitter title',
      },
      is_first_session: {
        type: 'boolean',
        description: 'First session flag',
      },
      desktop_url: {
        type: 'string',
        description: 'Desktop URL',
      },
      tags: {
        type: 'array',
        items: { type: 'string' },
        description: 'Array of tags',
      },
      marketing_title: {
        type: 'string',
        description: 'Marketing title',
      },
      twitter_description: {
        type: 'string',
        description: 'Twitter description',
      },
      og_title: {
        type: 'string',
        description: 'Open Graph title',
      },
    },
  }),
};
