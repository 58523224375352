import { Schema } from '../../Entities/Schema.entity';
import { createSchema } from '../../Utils/CreateSchema';

export const accountVerifiedSchema: Schema = {
  ...createSchema({
    version: '1-0-1',
    description: 'Tracks when a user verifies their account',
    name: 'account_verified',
    properties: {},
  }),
};
