import { Schema } from '../../Entities/Schema.entity';
import { createSchema } from '../../Utils/CreateSchema';

export const signupSubmitSchema: Schema = {
  ...createSchema({
    version: '1-0-1',
    description: 'Tracks when a user completes the account signup process',
    name: 'signup_submit',
    properties: {
      source: {
        type: 'string',
        description: 'The source where the signup was initiated from',
      },
    },
  }),
};
