import { Schema } from '../../Entities/Schema.entity';
import { createSchema } from '../../Utils/CreateSchema';

export const image360ViewedSchema: Schema = {
  ...createSchema({
    version: '1-0-1',
    description: 'Schema for tracking when a user views a 360-degree image',
    name: '360_image_viewed',
    properties: {
      listing_id: {
        type: 'string',
        description: 'The id of the listing',
      },
      user_id: {
        type: 'string',
        description: 'The id of the user',
      },
    },
    required: ['listing_id', 'user_id'],
  }),
};
