import { Schema } from '../../Entities/Schema.entity';
import { createSchema } from '../../Utils/CreateSchema';

export const componentViewedSchema: Schema = {
  ...createSchema({
    version: '1-0-0',
    description: 'track if a page component has been viewed',
    name: 'component_viewed',
    properties: {
      comp_type: {
        type: 'string',
        description:
          'Component identifier, either the CMS content model id or the name of the component in the codebase',
      },
      comp_wording: {
        type: 'string',
        description: 'Title of the component',
      },
      comp_category: {
        type: 'string',
        description: 'A variant or option of the component type',
      },
      target_url: {
        type: 'string',
        description: 'The CTA URL if applicable',
      },
      comp_location: {
        type: 'string',
        description: 'The page type where the component was located.',
      },
    },
  }),
};
