import { Schema } from '../../Entities/Schema.entity';
import { createSchema } from '../../Utils/CreateSchema';

export const pdpViewedSchema: Schema = {
  ...createSchema({
    version: '1-0-0',
    description:
      'A listing view event, similar to the page view but specific event for marketing platforms',
    name: 'pdp_viewed',
    properties: {
      listing_id: {
        type: 'string',
        description: 'The id of the listing',
      },
    },
    required: ['listing_id'],
  }),
};
